import * as _ from "lodash";
import {callApi} from "./callApi";
import {CONSTANT} from "../shared/constant";

export const register = (data: any) => {
    let endpoint = "/auth/phone/register";
    const method = 'POST'
    return callApi(endpoint, method, data, 'v1')
}

export const confirmOTP = (data: any) => {
    let endpoint = "/auth/phone/confirm";
    const method = 'POST'
    return callApi(endpoint, method, data, 'v1')
}


export const login = (data: any) => {
    let endpoint = "/auth/referees/login";
    const method = 'POST'
    return callApi(endpoint, method, data, 'v1')
}

export const myProfile = () => {
    let endpoint = "/auth/me";
    const method = 'GET'
    return callApi(endpoint, method, '', 'v1')
}

export const getMyCalibrationHistory = () => {
    let endpoint = "/auth/me/calibration/results";
    const method = 'GET'
    return callApi(endpoint, method, '', 'v1')
}


export const getUserById = (playerId: any) => {
    let endpoint = `/players/${playerId}`;
    const method = 'GET'
    return callApi(endpoint, method, '', 'v1')
}

export const getLeagues = ( data: any) => {
    let endpoint = `/leagues?`;
    const method = 'GET'
    if (data.limit) {
        endpoint += `limit=${data.limit}`
    } else {
        endpoint += `limit=${CONSTANT.limit}`
    }
    if (data.page) {
        endpoint += `page=${data.page}`
    } else  {
        endpoint += `page=${data.page}`
    }
    return callApi(endpoint, method, '', 'v1')
}

export const getLeagueById = (id: any) => {
    let endpoint = `/leagues/${id}`;
    const method = 'GET'
    return callApi(endpoint, method, '', 'v1')
}

export const getExperts = () => {
    let endpoint = "/players?onlyExpert=true";
    const method = 'GET'
    return callApi(endpoint, method, '', 'v1')
}


export const getRankGroupClass = (params: any = {}) => {
    let endpoint = "/ranks/groups";
    if (params) {
        endpoint = AppendGetRequestParameter(endpoint, params)
    }
    const method = 'GET'
    return callApi(endpoint, method, '', 'v1')
}

export const getCalibrationTypeList = () => {
    let endpoint = "/calibrations";
    const method = 'GET'
    return callApi(endpoint, method, '', 'v1')
}

export const calibratePlayer = (data:any) => {
    let endpoint = "/auth/expert/calibrate";
    const method = 'POST'
    return callApi(endpoint, method, data, 'v1')
}

export const calibratePlayerLevel = (data:any) => {
    let endpoint = "/experts/calibrate/"+ data.playerId;
    delete(data.playerId)
    const method = 'POST'
    return callApi(endpoint, method, data, 'v1')
}

const AppendGetRequestParameter = (url: string, params: any) => {
    if (url.indexOf('?') === -1) {
        url += '?'
    }
    if (_.isObject(params)) {
        _.forOwn(params, (val, key) => {
            url += `${key}=${val}`
        })
    }
    return url
}

export const getAllMatches = (filter:any) => {
    let endpoint = "/referees/matches";
    const method = 'GET'
    if (filter && filter.status) {
        endpoint += '?status='+filter.status+"&limit=50"
    }
    return callApi(endpoint, method, '', 'v1')
}

export const getGetMatchDetailById = (id:string) => {
    let endpoint = "/matches/"+id;
    const method = 'GET'
    return callApi(endpoint, method, '', 'v1')
}

export const getMatchResultById = (id:string) => {
    let endpoint = "/matches/"+id+"/results";
    const method = 'GET'
    return callApi(endpoint, method, '', 'v1')
}

export const updateMatchTable = (matchId:string, body:object) => {
    let endpoint = "/referees/matches/"+matchId;
    const method = 'PATCH'
    return callApi(endpoint, method, body, 'v1')
}

export const confirmMatch = (matchId:string, body:object) => {
    let endpoint = `/referees/matches/${matchId}/results`;
    const method = 'POST'
    return callApi(endpoint, method, body, 'v1')
}

export const getPlayersInLeague = (leagueId: string, limit: any, page:any) => {
    let endpoint = `/leagues/${leagueId}/participants?`;
    const method = 'GET'
    if (limit) {
        endpoint += `limit=${limit}`
    } else {
        endpoint += `limit=${CONSTANT.limit}`
    }
    if (page) {
        endpoint += `page=${page}`
    } else  {
        endpoint += `page=${page}`
    }
    return callApi(endpoint, method, '', 'v1')
}

export const getPlayerInLeagueData = (leagueId: string, playerId: string) => {
    let endpoint = `/leagues/${leagueId}/participants/${playerId}`;
    const method = 'GET'
    return callApi(endpoint, method, '', 'v1')
}

export const checkinLeagueByLeaguePlayerId = (leaguePlayerId: string) => {
    let endpoint = "/referees/leagues/" + leaguePlayerId + '/checkin';
    const method = 'POST'
    return callApi(endpoint, method, {}, 'v1')
}

export const checkoutLeagueByLeaguePlayerId = (leaguePlayerId: string) => {
    let endpoint = "/referees/leagues/" + leaguePlayerId + '/checkout';
    const method = 'POST'
    return callApi(endpoint, method, {}, 'v1')
}
